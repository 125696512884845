import React, {useState, useEffect} from 'react';
import styled, {css} from 'styled-components';
import {Button, theme, semiBoldBrandFont, boldBrandFont, brandFont} from '@partssourceinc/react-ui-core';
import * as PropTypes from 'prop-types';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faCheckCircle} from '@fortawesome/pro-light-svg-icons';
import {faScrewdriverWrench} from '@fortawesome/pro-light-svg-icons'; 
import {faEye, faEyeSlash, faGripDotsVertical} from '@fortawesome/pro-solid-svg-icons';
import {formatMoney} from 'utility';

const border = (side = null) => css`
    border${side ? `-${side}` : ''}: 2px solid ${theme.grey5};
`;

const MobileContainer = styled.div`
    margin-top: 50px;
    display: none;
    ${brandFont('14px')}

    @media (max-width: ${theme.screenMDMax}) {
        display: flex;
        flex-direction: column;
    }
`;

const Container = styled.table`
    margin-top: 50px;
    display: none;

    @media (min-width: ${theme.screenLGMin}) {
        display: block;
    }

    ${brandFont('14px')}

    .headers {
        min-width: 260px;
    }

    .highlighted {
        border: 3px solid #29a10f;

        @media (min-width: ${theme.screenMDMin}) {
            width: 243px
        }

        @media (min-width: ${theme.screenLGMin}) {
            width: 286px
        }

        @media (min-width: ${theme.screenXLMin}) {
            width: 360px
        }
    }

    .default {
        border: 3px solid ${theme.grey5};

        @media (min-width: ${theme.screenMDMin}) {
            width: 243px
        }

        @media (min-width: ${theme.screenLGMin}) {
            width: 286px
        }

        @media (min-width: ${theme.screenXLMin}) {
            width: 360px
        }
    }
`;

const Description = styled.div`
    ${boldBrandFont('16px')};
    color: ${theme.blue};
    cursor: pointer;
    z-index: 5;
    position: relative;

    &:hover {
        text-decoration: underline;
    }
`;

const RowIcon = styled(FontAwesomeIcon)`
    font-size: 12px;
    color: ${props => props.disabled ? 'lightgray' : 'black'};
`;

const Notes = styled.div`
    cursor: pointer;
    display: inline-flex;
    align-items: center;
    margin-bottom: 5px;

    > span {
        color: #005DA6;
        ${boldBrandFont('16px')};
        text-decoration: none;
        margin-left: 10px;
        z-index: 5;
    }
`;

const PriceInfo = styled.td`
    gap: 10px;
    padding: 30px 24px 24px 24px;

    button {
        margin-top: 10px;
        width: calc(100%);
        z-index: 5;
    }

    .yourPrice {
        ${semiBoldBrandFont('14px')};
        color: #767676;
    }

    .price {
        text-transform: lowercase;

        span {
            ${boldBrandFont('30px')};
        }
    }

    .message {
        ${boldBrandFont('14px')};
        color: ${theme.grey2};
    }

    .icon {
        ${boldBrandFont('16px')};
        color: #17ab78;
        display: flex;
        align-items: center;
        height: 48px;

        span {
            margin-left: 5px;
        }
    }
`;

const MobileOption = styled.table`
    margin-top: 40px;
    box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.25);

    ${props => props.$isHighlighted && css`
        box-shadow: none;
        border: 3px solid #29a10f;
    `}
`;

const MobileHeader = styled.td`
    padding-left: 16px;
    padding-right: 23px;
    ${border('right')}
`;

const Field = styled.td`
    height: 30px;
    line-height: 20px;
    cursor: pointer !important;
    text-align: center;
    padding: 4px 24px 4px 24px;

    button {
        height: 32px;
        z-index: 5;
    }

    &:not(:first-of-type) {
        ${semiBoldBrandFont('16px')};
    }

    ${props => props.$isHighlighted && props.$isVisible && css`
       background-color: #edfde9;
    `}
`;

const ContractBadge = styled.div`
    background-color: rgba(20, 114, 125, 0.15);
    border-radius: 3px 3px 3px 3px;
    width: 80px;
    height: 22px;
    letter-spacing: 0;
    margin-top: 5px;

    span {
        color: rgb(20, 114, 125);
        font-size: 12px;
        padding-left: 11px;
        font-weight: 700;
        line-height: 22px;
    }
`;

const ColumnTitle = styled.div`
    display: flex;
    justify-content: space-between;
    margin-bottom: 15px;
`;

const HighlightedIcon = styled(FontAwesomeIcon)`
    font-size: 40px;
    position: absolute;
    left: 50%;
    transform: translate(-50%, 0%);
    top: -20px;
    z-index: 10;
    background-color: #ffffff;
    color: #29a10f;
`;

const Header = styled.th`
   text-align: left;
   vertical-align: top;
`;

const FieldRow = styled.tr`
    ${border()}

    ${props => !props.$isVisible && css`
        background: #f5f5f5;
        color: #DADBDB;
    `}
`;

const BottomRow = styled.tr`
    height: 1px;
    @supports (-moz-appearance:none) {
        height: 100%;
        min-height: 1px;
    }
`;

const QuoteOptions = (props) => {
    const {
        isAdmin, 
        options: optionsProp, 
        fields: fieldsProp, 
        onViewNotes, 
        onSelected, 
        onAddToCart, 
        onFieldClick,
        onToggleFieldVisibility, 
        onToggleOptionVisibility,
        onViewSupplierNotes,
        onViewSupplierAttachments,
    } = props;

    const [fields, setFields] = useState(fieldsProp.filter((f) => isAdmin || f.isVisible));
    const [options, setOptions] = useState(optionsProp.filter((f) => isAdmin || f.isVisible));

    fields.sort((a,b) => a.sortOrder - b.sortOrder);
    options.sort((a,b) => a.sortOrder - b.sortOrder);

    useEffect(() => {
        let opts = [...optionsProp.filter((f) => isAdmin || f.isVisible)];
        opts.sort((a,b) => a.sortOrder - b.sortOrder);
        setOptions(opts);
    }, [optionsProp]);

    useEffect(() => {
        let $fields = [...fieldsProp.filter((f) => isAdmin || f.isVisible)];
        $fields.sort((a,b) => a.sortOrder - b.sortOrder);
        setFields($fields)
    }, [fieldsProp]);

    const renderTop = (option, index, desktop = true) => {

        return (<>
            <Header 
                $isHighlighted={option.isHighlighted} colSpan={desktop ? '1' : '2'}>
                {option.isHighlighted && <div style={{position: 'relative'}}>
                    <HighlightedIcon icon={faCheckCircle} />
                </div>}
                
                <div style={{display: 'flex', flexDirection: 'column', padding: '24px 24px 30px 24px'}}>
                    {isAdmin && <ColumnTitle>
                        <RowIcon disabled={true} icon={faGripDotsVertical} />
                        <RowIcon icon={option.isVisible ? faEye : faEyeSlash} 
                            onClick={() => onToggleOptionVisibility?.(index)} />
                    </ColumnTitle>} 
                    <Description to={{pathname: '#'}} onClick={() => onSelected?.(option)}>
                        {option.title}
                    </Description>
                    <div>
                        {`Purchase Type: `}
                        {option.purchaseChoiceId === 2 && <img src="/images/exchange.svg" alt="Exchange Item" style={{height: '15px', padding: '0 2px'}} />}
                        <span style={{fontWeight: '600'}}>{option.purchaseType}</span>
                    </div>
                    <div style={{marginBottom: 15}}>
                        {`Returnable: `}
                        <span style={{fontWeight: '600'}}>
                            {option.returnable ? `Returnable` : `Not Returnable`}
                        </span>
                    </div>
                    {!isAdmin ? <Notes onClick={() => onViewNotes?.(option)}>
                        <i className="fa fa-sticky-note-o" aria-hidden="true" style={{fontSize: '19px', marginLeft: '2px'}} /> 
                        <span>
                            {`Notes (${option.noteCount})`}
                        </span>
                    </Notes> : null}
                    {option.isRepair && option.isSupplierNotesPresent ?
                        <Notes onClick={() => onViewSupplierNotes?.(option)}>
                            <FontAwesomeIcon icon={faScrewdriverWrench} style={{fontSize: '18px'}} />
                            <span>
                                {`Supplier Evaluation Info`}
                            </span>
                        </Notes> : null
                    }
                    {option?.isRepair && option.supplierAttachmentCount !== 0 ?
                        <Notes onClick={() => onViewSupplierAttachments?.(option)}>
                            <i className="fa fa-copy" aria-hidden="true" style={{fontSize: '18px', marginTop: '2px'}} />
                            <span>
                                {`Supplier Attachments (${option.supplierAttachmentCount})`}
                            </span>
                        </Notes> : null
                    }
                    {option.isContractProOption && <ContractBadge><span>CONTRACT</span></ContractBadge>}
                </div>
            </Header>
            {desktop && index + 1 !== options.length ? <td /> : null}
        </>)
    }

    const renderBottom = (option, index, desktop = true) => {
        return (<>
            <PriceInfo style={{height: 'inherit'}} colSpan={desktop ? '1' : '2'}>
                <div style={{height: '100%', display: 'flex', 
                    flexDirection: 'column', justifyContent: 'space-between'}}>
                    <div style={{display: 'flex', flexDirection: 'column', gap: '10px'}}>
                        {!option.hidePricing && <>
                            <div className="yourPrice">Your Price: </div>
                            <div className="price">
                                <span>{formatMoney(option.price)}</span>{` / ${option.unitOfMeasurement}`}
                            </div>
                        </>}
                        <div className="message">{option.message}</div>
                    </div>

                    {(!isAdmin && !option.inCart) ? 
                        <Button loading={option.loading} onClick={() => onAddToCart?.(option, index)} data-ea-cta-link={option.condition}>
                            Add to Cart 
                        </Button> 
                        : !isAdmin ? <div className="icon">
                            <FontAwesomeIcon icon={faCheckCircle} style={{fontSize: '20px'}} />
                            <span>Accepted</span>
                        </div> : null}
                </div>
            </PriceInfo>
            {desktop && index + 1 !== options.length ? <td /> : null}
        </>);
    }

    const renderDesktop = (field, index) => {
        return (<FieldRow key={`opt_${index}`} $isVisible={field.isVisible}>
            <td style={{paddingLeft: '16px', paddingRight: '16px'}}>
                {isAdmin ? (<>
                    <RowIcon disabled={true} icon={faGripDotsVertical} style={{marginRight: '10px'}} />
                    <RowIcon disabled={!field.isVisible} style={{marginRight: '10px'}}
                        icon={field.isVisible ? faEye : faEyeSlash} 
                        onClick={() => onToggleFieldVisibility?.(index)} />
                    {field.label}
                </>) : field.label}
            </td>
            {options.map((o, i) => 
                (<>
                    <Field key={i} $isVisible={field.isVisible}
                        onClick={() => onFieldClick?.(i, field)}
                        $isHighlighted={(o.highlightedFields || []).some(hf => hf === field.name)}>
                        {field.name in o && o[field.name] !== null ? 
                            typeof o[field.name] === 'function' ? 
                                o[field.name](o) : o[field.name] : 'N/A'}</Field>
                    {i + 1 !== options.length ? <td><div style={{width: '30px'}} /></td> : null}
                </>))
            }
        </FieldRow>);
    }

    return (<>
        <Container>
            <colgroup>
                <col className="headers" />
                {options.map((o, i) => {
                    return (<>
                        <col className={`${o.isHighlighted ? 'highlighted' : 'default'}`} />
                        {i + 1 !== options.length && <col style={{width: '30px'}} />}
                    </>)
                })}
            </colgroup>
            <tbody>
                <tr>
                    <th />
                    {options.map((o, i) => renderTop(o, i))}
                </tr>
                {fields.map((f, index) => renderDesktop(f, index))}
                <BottomRow>
                    <td />
                    {options.map((o, i) => renderBottom(o, i))}
                </BottomRow>
            </tbody>
        </Container>
        <MobileContainer>
            {options.map((o, i) => (<MobileOption key={`opt_${i}`} $isHighlighted={o.isHighlighted}>
                <tbody>
                    <FieldRow $isVisible={true}>
                        {renderTop(o, i, false)}
                    </FieldRow>
                    
                    {fields.map((field, index) => (<FieldRow $isVisible={field.isVisible} key={`f_${index}`}>
                        <MobileHeader>{field.label}</MobileHeader>
                        <Field $isVisible={field.isVisible}
                            onClick={() => onFieldClick?.(i, field)}
                            $isHighlighted={(o.highlightedFields || []).some(hf => hf === field.name)}>
                            {field.name in o && o[field.name] !== null ? 
                                typeof o[field.name] === 'function' ? 
                                    o[field.name](o) : o[field.name] : 'N/A'}
                        </Field>
                    </FieldRow>))}
                    <FieldRow $isVisible={true}>
                        {renderBottom(o, i, false)}
                    </FieldRow>
                </tbody>
            </MobileOption>))}
        </MobileContainer>
    </>);
};

QuoteOptions.propTypes = {
    onSelected: PropTypes.func.isRequired,
    onAddToCart: PropTypes.func.isRequired,
    onViewNotes: PropTypes.func.isRequired,
    onFieldClick: PropTypes.func,
    options: PropTypes.arrayOf(PropTypes.shape({
        id: PropTypes.number.isRequired,
        index: PropTypes.number.isRequired,
        title: PropTypes.string.isRequired,
        purchaseType: PropTypes.string.isRequired,
        condition: PropTypes.string.isRequired,
        manufacturer: PropTypes.string.isRequired,
        unitOfMeasurement: PropTypes.string,
        message: PropTypes.string,
        isContractProOption: PropTypes.bool,
        price: PropTypes.number.isRequired,
        returnable: PropTypes.bool.isRequired,
        sortOrder: PropTypes.number.isRequired,
        noteCount: PropTypes.number.isRequired,
        isVisible: PropTypes.bool.isRequired,
        highlightedFields: PropTypes.arrayOf(PropTypes.string),
    })).isRequired,
    fields: PropTypes.arrayOf(PropTypes.shape({
        name: PropTypes.string.isRequired,
        label: PropTypes.string.isRequired,
        isVisible: PropTypes.bool.isRequired,
        sortOrder: PropTypes.number.isRequired,
    })).isRequired,
}

export default QuoteOptions;
