import axios from 'axios';
import * as React from 'react';
import moment from 'moment';
import {connect} from 'react-redux';
import {withRouter} from 'react-router';
import styled from 'styled-components';
import * as ReactRouterPropTypes from 'react-router-prop-types';
import _ from 'lodash';
import AssetInformationForm, {AssetFormType} from 'components/AssetInformationForm';
import {Button, Popup, boldBrandFont, theme} from '@partssourceinc/react-ui-core';
import InfoCenterMain from 'components/InfoCenter/InfoCenterMain';
import DeclineQuoteConfirmation from 'components/DeclineQuoteConfirmation';
import LineQuote from 'components/LineQuote';
import PageMetaData from 'components/PageMetaData';
import {ProductImage} from '@partssourceinc/react-ui-core';
import {getCompanyAndVendorFields, getPartCondition, getPurchaseChoice, leadTimeDescriptionRepair, leadTimeDescription, getVendorAttribute} from 'productUtility';
import * as CartStore from 'stores/Cart';
import * as UserStore from 'stores/User';
import {formatMoney, getCookie, getFormattedPhoneNumber} from 'utility';
import {defaultPhoneNumber} from 'data/DefaultPhoneNumber';

import 'less/quotedetails.less';
import {BrComponent} from '@bloomreach/react-sdk';
import QuoteOptions from './QuoteOptions';

const CertsWrapper = styled.span`
    display: flex;
    gap: 5px;
    
    img {
        height: 24px;
    }
`;

const QuoteSummary = styled.div`
    border-bottom: 1px solid #cccccc;
    width: 100%;
    font-size: 20px;
    margin: 50px 0px;
    height: 42px;
`;

const SupplierNote = styled.div`
    background-color: #F1F1F1;
    height: 100%;
    padding: 10px 20px 10px 20px;
    margin: 20px 0px 20px 0px;
`;

const DocName = styled.a`
    ${boldBrandFont('16px')};
    color: ${theme.blue};
    cursor: pointer;
    z-index: 5;
    position: relative;

    &:hover {
        text-decoration: underline;
    }
`;

@withRouter
@connect(state => ({user: state.user, bloomreach: state.bloomReach, sessionStorageAvailable: state.network.sessionStorageAvailable, hideNoEsdMessaging: state.system.siteSettings.hideNoEsdMessaging}), _.merge({}, CartStore.actionCreators, UserStore.actionCreators))
export default class QuoteDetails extends React.Component {
    static displayName = 'QuoteDetails';

    static propTypes = {
        ...ReactRouterPropTypes,
        ...CartStore.ActionShape,
        ...UserStore.ActionShape,
        user: UserStore.StateShape,
    };

    constructor(props) {
        super(props);

        this.state = {
            quote: {
                detail: null,
                options: [],
            },
            displayOptions: [],
            fields: [],
            selectedImageIndex: 0,
            showImagePopup: false,
            selectedPopupImageIndex: null,
            loading: false,
            showAssetInformationPopup: false,
            showAssetInformationAddToCartPopup: false,
            printQuote: false,
            showAttachmentPopup: false,
            showNotesPopup: false,
            showDeclineQuotePopup: false,
            isQuoteNotes: false,
            backToOrderHistory: (this.props.sessionStorageAvailable && sessionStorage.prevUrl) && sessionStorage.prevUrl.indexOf('/orders') >= 0,
            fromDashboardV1: sessionStorage.prevGrid === 'Dashboard',
            fromDashboardV2: sessionStorage.prevGrid === 'Command Center',
            selectedOption: {},
            vendorResearchId: null,
            companyFields: this.props.user.settings.requiredFields,
            isExchangeTermsChecked: false,
        };
        this.getCompanyLineFields = this.getCompanyLineFields.bind(this);
        this.handleImageSelected = this.handleImageSelected.bind(this);
        this.declineQuoteCancelHandler = this.handleDeclineQuoteCancel.bind(this)
        this.addToCart = this.addToCart.bind(this);
    }

    componentDidMount() {
        const {bloomreach: {isPreview}} = this.props;
        if (isPreview) {
            return;
        }

        this.loadQuoteDetails();
    }

    renderCerts(option, classPrefix) {
        return (
            <CertsWrapper className={`${classPrefix}_certs`}>
                {Object.keys(option.certifications).map((c, index) => (
                    <img key={`img_cert_${index}`} src={`/images/${getVendorAttribute(c)}`} alt={option.certifications[c]} />
                ))}
            </CertsWrapper>
        );
    }

    loadQuoteDetails() {
        const {match: {params: {lineItemId}}, hideNoEsdMessaging, user: {settings: {hidePricing}}} = this.props;

        this.setState({loading: true});
        axios.get(`/ShoppingService/api/v1/quotes/${lineItemId}`).then(response => {
            const {data: {quote, quote: {detail}}} = response;

            if (detail && detail.oItemNumber) {
                dataLayer.push({
                    brProductId: detail.oItemNumber,
                    brProductSku: detail.oItemNumber,
                });
            }

            let fields = [];
            let displayOptions = [];

            if (quote.options && quote.options.length > 0) {
                const highestVendorRanking = Math.max(...quote.options.map(o => o.vendorRanking));
                const [first] = [...quote.options];
                fields = (first.columns || []).map(item => ({name: item.code, isVisible: item.isVisible, label: item.name, sortOrder: item.displayOrder}));

                if (hidePricing) {
                    fields = fields.filter(f => f.name !== 'yourPrice');
                }

                displayOptions = quote.options.map((item, i) => {
                    const price = formatMoney(item.price);
                    const title = item.serviceInfo ? this.formattedTitle(detail.title) : item.description;
                    const condition = !item.serviceInfo ? item.conditionType : null;
                    const backorderStatus = item.purchaseChoiceId === 3 ? leadTimeDescriptionRepair(item.estimatedLeadTime)
                        : !item.isContractProOption ? leadTimeDescription(item.estimatedShipDate, null, item.shipCutoffUtc, hideNoEsdMessaging) : null;
                    const returnable = !item.isContractProOption && !item.serviceInfo ? item.returnStatus : null;
                    const vendorRanking = (item.vendorRanking === highestVendorRanking) ? 'Preferred' :
                        (item.vendorRanking > (highestVendorRanking - 20)) ? 'Good' : 'Satisfactory';
                    const purchaseType = getPurchaseChoice(item.purchaseChoiceId);

                    let vendor = {
                        id: item.vendorResearchId,
                        index: i,
                        purchaseType,
                        purchaseChoiceId: !item.serviceInfo ? item.purchaseChoiceId : null,
                        purchaseType: item.purchaseType,
                        title: title,
                        condition: condition,
                        returnable: returnable,
                        noteCount: (item.lineItemConditionalNotes && item.lineItemConditionalNotes.length) || 0,
                        isVisible: true,
                        warranty: !item.isContractProOption ? item.warranty : null,
                        qualityReturnRate: item.qualityReturnRate || null,
                        leadTime: item.estimatedLeadTime,
                        sortOrder: item.displayOrder,
                        unitOfMeasurement: item.uom || 'Each',
                        message: backorderStatus,
                        highlightedFields: item.columns.filter(c => c.isHighlighted).map(c => c.code),
                        vendorRanking,
                        psLabelUser: !item.canSendShippingLabel ? 'Yes' : 'No',
                        backorderStatus: backorderStatus,
                        certifications: item.certifications && Object.keys(item.certifications).length ? item.certifications : null,
                        vendorEsgBadge: (o) => {
                            if (!o.certifications) return null;
                            return this.renderCerts(o, 'option');
                        },
                        yourPrice: price,
                        price,
                        isContractProOption: item.isContractProOption ? true : false,
                        loading: false,
                        inCart: item.inCart,
                        isHighlighted: item.isHighlighted,
                        hidePricing,
                        detailUrl: item.detailUrl,
                        isRepair: quote.detail.orderType,
                        isSupplierNotesPresent: item?.vendorEvaluationInfo?.customerDiagnosisNote || item?.vendorEvaluationInfo?.customerRepairNote ? true : false,
                        supplierAttachmentCount: item?.vendorEvaluationInfo?.attachments ? item?.vendorEvaluationInfo?.attachments.length : 0,
                    }
    
                    return vendor;
                });
            }
            
            this.setState({
                loading: false,
                quote: quote,
                isUrgency: (detail.priority === 1),
                fields,
                displayOptions: [...displayOptions],
            });
        });
    }

    getCompanyLineFields() {
        const {quote: {detail}} = this.state;
        let compFields = getCompanyAndVendorFields(false, false, detail, detail.facilityId);

        this.setState({companyFields: compFields, showAssetInformationPopup: true});
    }
    

    declineQuote = async (lineItemId, history) => {
        const response = await axios.delete(`/ShoppingService/api/v1/quotes/${lineItemId}`);

        const {quote: {options}, quote} = this.state;
        const hasEvalFee = !!options[0]?.evaluationFee;
        const isRepair = quote && quote.detail && quote.detail.orderType === 'Repair';

        if (response.status === 200) {
            if (isRepair) {
                history.push(hasEvalFee ? '/cart' : '/repairs?tab=repairing')
            } else {
                history.goBack();
            }
        } else {
            alert('Something went wrong!');
        }
    }

    handleDeclineQuoteCancel() {
        this.setState({showDeclineQuotePopup: false})
    }

    verifyRequiredFields(option, item) {                
        const {quote: {detail,vendorFields, isExchangeTermsChecked}} = this.state;
        const {sessionStorageAvailable} = this.props;

        const showFormulary = !option.isFormularyOption && option.nonFormularyApprovalRequired;

        // If it has fields from url, set values
        const urlFields = (sessionStorageAvailable && sessionStorage.urlFields) ? JSON.parse(sessionStorage.urlFields) : [];
        const hasUrlFields = urlFields && urlFields.length > 0;
        const needsExchangeTermsAcknowledgement = !isExchangeTermsChecked && option.purchaseChoiceId === 2;

        if (_.isEmpty(detail.fields) || vendorFields.filter(x => x.companyId === option.vendorId).length > 0 || showFormulary || hasUrlFields || needsExchangeTermsAcknowledgement) {
            let customFields = getCompanyAndVendorFields(false, false, option, detail.facilityId).map(field => {
                // If a quote was created in PartsFinder, for some reason, the fields don't get their lineItemId and orderId set
                return !field.lineItemId || field.lineItemId === 0 ? _.assign(field, {lineItemId: detail.lineItemId, orderId: detail.orderId}) : field;
            });

            vendorFields.filter(x => x.companyId === option.vendorId).map(f => {
                let cf = customFields.find(cf => cf.fieldDefinitionUid === f.fieldUid);
                if (!cf) {
                    customFields.push(Object.assign(f, {lineItemId: detail.lineItemId, orderId: detail.orderId}));
                } else {
                    Object.assign(cf, f);
                }
            })
            
            // If it has fields from url, set values
            if (hasUrlFields) {
                customFields = customFields.map(field => {
                    const urlField = urlFields.find(f => f.fieldUid === field.fieldUid);
                    if (urlField) {
                        field.value = urlField.value;
                    }
                    return field;
                });
            } else {                
                // Map Any existing Values
                if (detail.fieldValues && detail.fieldValues.length > 0) {
                    customFields.forEach(c => {
                        c.value = (detail.fieldValues.find(d => d.fieldUid === c.fieldUid) || {}).value || ''
                    })
                }               
            } 
             
            const hasRequiredFields = customFields.filter(x => x.isRequired).length > 0;           
            if (hasRequiredFields || showFormulary || hasUrlFields || needsExchangeTermsAcknowledgement) {
                this.setState({showAssetInformationAddToCartPopup: true, customFields: customFields, selectedOption: option});
                return;
            }
        }
                
        item.loading = true;

        if (!showFormulary)
            this.saveFormularyFields([]);

        this.addToCart(option);
    }

    addToCart(option) {   
        option.loading = true;     
        const {quote: {detail}, isExchangeTermsChecked} = this.state;
        const {user: {info: {contactId}, facilities}, addLegacyQuoteItemToCart, loadUserCart, sessionStorageAvailable} = this.props;
        const groupId = (facilities.find(x => x.facilityId === detail.facilityId) || {}).groupId || 0;
                
        const id_ins = getCookie('id_ins');

        const quoteOption = {
            lineItemId: detail.lineItemId,
            purchaseChoiceId: option.purchaseChoiceId,
            vendorResearchId: option.vendorResearchId,
            userId: contactId,
            groupId: groupId,
            imagePath: (detail.thumbnailImage || {}).fullPath,
            customerOrderKey: sessionStorageAvailable && (sessionStorage.wo_id ? sessionStorage.wo_id : ''),
            id_ins,
            IsExchangeTermsChecked: isExchangeTermsChecked,
        };

        dataLayer.push({
            'event': 'brAddToCart',
            'brProductId': detail.oItemNumber,
            'brProductSku': detail.oItemNumber,
        }, {
            event: 'addToCart',
            ecommerce: {
                add: {
                    products: [{
                        name: detail.partNumber,
                        id: detail.oItemNumber,
                        price: option.price,
                        brand: detail.manufacturer,
                        category: detail.modalityId,
                        variant: `${getPartCondition(option.conditionId)} -  ${getPurchaseChoice(option.purchaseChoiceId)}`,
                        quantity: detail.quantity,
                    }],
                    id_ins,
                },
            },
        });

        addLegacyQuoteItemToCart(quoteOption).then(x => {
            this.loadQuoteDetails();
            loadUserCart();
            this.setState({
                showAssetInformationPopup: false,
                showAddToCartConfirmation: true,
                addingToCart: false,
            });
        });
    }

    orderDetailChange(requester) {
        const {quote} = this.state;
        quote.detail.requestor = requester.fullName;
        quote.detail.requesterId = requester.contactId;
        this.setState({quote});
    }

    getFieldValues(values, formType, requester) {

        const {user: {info: {contactId}}} = this.props;
        const {quote: {detail}, isUrgency} = this.state;
        axios.all([this.saveFields(values, contactId), this.saveRequester(requester, contactId), this.saveUrgency(detail.lineItemId, detail.priority, isUrgency)]).then(() => {
            this.loadQuoteDetails(false);
        });
        this.setState({showAssetInformationPopup: false});
    }

    saveFields(fields, contactId) {
        return axios.post(`/ShoppingService/api/v1.0/cart/saveFields/${contactId}`, fields);
    }

    saveFormularyFields(fields) {
        const {quote: {detail: {lineItemId}}} = this.state;
        fields.forEach(x => x.lineItemId = lineItemId);
        return axios.post('/ShoppingService/api/v1.0/cart/saveFormularyFields', fields || []);
    }

    saveRequester(requester) {

        if (requester && requester.contactId) {
            let {quote: {detail}} = this.state;
            return axios.post('/ShoppingService/api/v1.0/cart/updateRequester', {
                OrderId: detail.orderId,
                UserId: requester.contactId,
            });
        } else {
            return new Promise((resolve) => {
                resolve();
            });
        }
    }
    saveUrgency(lineItemId, priority, urgency) {
        if ((!urgency && priority === 1) || (urgency && priority === 2))
            return axios.post(`/ShoppingService/api/v1.0/cart/updatePriority/${lineItemId}/${urgency}`);
        return new Promise((resolve) => {
            resolve();
        });
    }
    saveAndProceedToCart(values, formType, requester, isUrgency, formularyFields) {
        const {selectedOption} = this.state;
        const {user: {info: {contactId}}} = this.props;

        axios.all([this.saveFields(values, contactId), this.saveFormularyFields(formularyFields)]).then(() => {
            this.addToCart(selectedOption);
            this.setState({showAssetInformationAddToCartPopup: false});
        });
    }

    renderAssetInformationPopup() {
        const {customFields, quote, selectedOption} = this.state;
        const priority = (quote.detail.priority === 1);
        const showFormulary = !selectedOption.isFormularyOption && selectedOption.nonFormularyApprovalRequired;       

        return (
            <AssetInformationForm
                fields={customFields}
                onSubmit={::this.saveAndProceedToCart}
                formType={AssetFormType.Both}
                readOnly={false}
                isRepairOption={quote.detail?.orderTypeId === 4}
                onCancel={() => this.setState({showAssetInformationAddToCartPopup: false})}
                showRequester={false}
                allowMissingFields={false}
                priority = { priority }
                onCheckBoxUpdate = {(e) => this.setState({isUrgency: e.checked})}
                showFormulary={showFormulary}
                isExchangeTermsRequired={selectedOption.purchaseChoiceId === 2}                
                onExchangeTermsAccepted={(checked) => this.setState({isExchangeTermsChecked: checked})}
            />
        );
    }

    formattedTitle(title) {
        return title.split(' by ')[0];
    }

    renderQuote() {
        const {showAssetInformationPopup,showAssetInformationAddToCartPopup,showAttachmentPopup, showImagePopup, showNotesPopup, showDeclineQuotePopup, isQuoteNotes, printQuote, quote, vendorResearchId, displayOptions, fields, addingToCart, showSupplierEvaluationPopup, showSupplierAttachmentPopup,
            quote: {detail: {title, requesterId, orderId, lineItemId, dateCreated, partNumber, orderType, quantity, facilityName, requestor,modelNumber, serviceInfo, fieldValues,priority,status,attachments, notes}, options}} = this.state;
        const {quote: {detail}} = this.state
        const {user: {settings: {hidePricing, dedicatedPhoneNumber}}, history} = this.props;
        const reqFields = showAssetInformationPopup ? fieldValues.filter(x => x.isRequired) : [];
        const optFields = showAssetInformationPopup ? fieldValues.filter(x => !x.isRequired) : [];
        const _priority = (priority === 1);
        const dedicatedPhoneNumberStr = dedicatedPhoneNumber ?
            getFormattedPhoneNumber(dedicatedPhoneNumber) : defaultPhoneNumber;
        const isRepair = quote && quote.detail && quote.detail.orderType === 'Repair';
        
        return (
            <div>
                <div className="row quoteRow">
                    <div className="col-md-9 quoteCol">
                        <h1>{serviceInfo ? this.formattedTitle(title) : title}</h1>
                    </div>
                    <div className="col-md-3 quoteCol">
                        {
                            status !== 'Requested'
                                ? (serviceInfo ? <strong className="quote-text">For questions or to cancel this quote, call <a href={'tel:+1' + dedicatedPhoneNumberStr}>{dedicatedPhoneNumberStr}</a> <br /></strong> : 
                                    (!detail?.isRepairDeclined ? <Button secondary={true} className="quote-button-gray" onClick={() => isRepair ? this.setState({showDeclineQuotePopup: true}) : this.declineQuote(lineItemId, history, null)}>Decline Quote</Button> : null))
                                : (<Button secondary={true} className="quote-button-gray" onClick={() => this.declineQuote(lineItemId, history)}>Cancel Quote</Button>)
                        }
                        <Button secondary={true} className="quote-button-white" onClick={() => this.setState({printQuote: true})}>Print Quote</Button>
                    </div>
                </div>

                <div className="row quoteRow" style={{paddingTop: '15px'}}>
                    <div className="col-md-4 quoteCol">
                        {lineItemId && <div className="row quoteRow">
                            <div className="item-attribute">
                                <span>Ref #:</span>
                                <span>{lineItemId}</span>
                            </div>
                        </div>}
                        {dateCreated && <div className="row quoteRow">
                            <div className="item-attribute">
                                <span>Date Entered:</span>
                                <span>
                                    {new Date(dateCreated).toLocaleDateString('en-US')}
                                </span>
                            </div>
                        </div>}
                        {partNumber && <div className="row quoteRow">
                            <div className="item-attribute">
                                <span>Item #:</span>
                                <span>{partNumber}</span>
                            </div>
                        </div>}
                        {orderType && <div className="row quoteRow">
                            <div className="item-attribute">
                                <span>Order Type:</span>
                                <span>{orderType}</span>
                            </div>
                        </div>}
                        {quantity && <div className="row quoteRow">
                            <div className="item-attribute">
                                <span>Quantity:</span>
                                <span>{quantity}</span>
                            </div>
                        </div>}
                        {facilityName && <div className="row quoteRow">
                            <div className="item-attribute">
                                <span>Facility:</span>
                                <span>{facilityName}</span>
                            </div>
                        </div>}
                        { requestor && <div className="row quoteRow">
                            <div className="item-attribute">
                                <span>Requester:</span>
                                <span>{requestor}</span>
                            </div>
                        </div>}
                        {serviceInfo && (
                            <React.Fragment>
                                {serviceInfo.serviceType && <div className="row quoteRow">
                                    <div className="item-attribute">
                                        <span>Service Type:</span>
                                        <span>{serviceInfo.serviceType}</span>
                                    </div>
                                </div>}
                                {serviceInfo.serviceNeeded && <div className="row quoteRow">
                                    <div className="item-attribute">
                                        <span>Service Description:</span>
                                        <span>{serviceInfo.serviceNeeded}</span>
                                    </div>
                                </div>}
                                {serviceInfo.turnaroundTime && <div className="row quoteRow">
                                    <div className="item-attribute">
                                        <span>Timeframe Needed by:</span>
                                        <span>{serviceInfo.turnaroundTime}</span>
                                    </div>
                                </div>}
                                {modelNumber &&
                                <div className="row quoteRow">
                                    <div className="item-attribute">
                                        <span>Model Number:</span>
                                        <span>{modelNumber}</span>
                                    </div>
                                </div>}
                                {serviceInfo.serviceTechnicianCredentials && <div className="row quoteRow">
                                    <div className="item-attribute">
                                        <span>Engineering Credentials:</span>
                                        <span>{serviceInfo.serviceTechnicianCredentials}</span>
                                    </div>
                                </div>}
                                {serviceInfo.locationDetail && <div className="row quoteRow">
                                    <div className="item-attribute">
                                        <span>Location Details:</span>
                                        <span>{serviceInfo.locationDetail}</span>
                                    </div>
                                </div>}
                            </React.Fragment>
                        )}
                    </div>
                    <div className="col-md-5 quoteCol quote-col-right">
                        <div className="asset-information">
                            {` Asset Information `}
                            <span className="edit-asset-info" onClick={() => this.getCompanyLineFields()}>
                                Edit
                            </span>
                            {this.renderFields(fieldValues)}
                        </div>
                    </div>
                    <div className="col-md-3 quoteCol">
                        <div className="row quoteRow attachment-line">
                            <i className="fa fa-copy" aria-hidden="true" style={{fontSize: '19px', marginTop: '10px'}} />
                            <span className="attachments" onClick={() => this.setState({showAttachmentPopup: true})}>
                                {`Attachments(${attachments ? attachments.length : 0})`}
                            </span>
                        </div>
                        <div className="row quoteRow attachment-line">
                            <i className="fa fa-sticky-note-o" aria-hidden="true" style={{fontSize: '20px', marginTop: '10px'}} />       
                            <span className="attachments" onClick={() => this.setState({showNotesPopup: true, isQuoteNotes: false})}>
                                {`Notes(${notes ? notes.length : 0})`}
                            </span>
                        </div>
                    </div>
                </div>

                {
                    status !== 'Requested' && status !== 'Initiated'
                        ? <div style={{width: '100%'}}>
                            <QuoteSummary>Quote Summary</QuoteSummary>
                            <QuoteOptions 
                                options={displayOptions}
                                fields={fields}
                                onSelected={(item) => {
                                    if (item.detailUrl) {
                                        history.push(item.detailUrl);
                                    }
                                }}
                                onAddToCart={(item) => {                                                                        
                                    this.verifyRequiredFields(options[item.index], item)
                                }}
                                onViewNotes={(item) => this.setState({showNotesPopup: true, isQuoteNotes: true, vendorResearchId: item.id})}
                                onViewSupplierNotes={(item) => this.setState({showSupplierEvaluationPopup: true, vendorResearchId: item.id})
                                }
                                onViewSupplierAttachments={(item) => this.setState({showSupplierAttachmentPopup: true, vendorResearchId: item.id})}
                            />
                        </div> : this.renderRequestedQuote()
                }
                {
                    printQuote
                        ? <LineQuote quote={quote} hidePricing={hidePricing} onClose={() => this.setState({printQuote: false})} />
                        : null
                }
                {
                    showAssetInformationPopup
                        ? <InfoCenterMain
                            fields={fieldValues}
                            reqFields={reqFields}
                            optFields={optFields}
                            activeTab="Asset"
                            onSubmit={::this.getFieldValues}
                            onOrderDetailChange={::this.orderDetailChange}
                            formType={AssetFormType.Both}
                            readOnly={false}
                            onCancel={() => this.setState({showAssetInformationPopup: false})}
                            showRequester={false}
                            lineItemId={lineItemId}
                            vendorResearchId={vendorResearchId}
                            orderId = {orderId}
                            requesterId={requesterId}
                            priority={_priority}
                            isQuoteNotes={isQuoteNotes}
                            onCheckBoxUpdate={(e) => this.setState({isUrgency: e.checked})}
                        />
                        : <div />
                }
                {
                    showAttachmentPopup
                        ? <InfoCenterMain
                            onCancel={() => this.setState({showAttachmentPopup: false})}
                            onSubmit={::this.getFieldValues}
                            onOrderDetailChange={::this.orderDetailChange}
                            reqFields={reqFields}
                            fields={fieldValues}
                            optFields={optFields}
                            lineItemId = {lineItemId}
                            orderId={orderId}
                            requesterId={requesterId}
                            activeTab="Attachments"
                            priority={priority}
                            isQuoteNotes={isQuoteNotes}
                            onCheckBoxUpdate={(e) => this.setState({isUrgency: e.checked})}
                        />
                        : <div />
                }
                {
                    showNotesPopup
                        ? <InfoCenterMain
                            onQuoteNotesSaved={(newNotes) => {
                                const updatedQuote = {...quote};
                                const updatedDisplayOptions = [...displayOptions];
                                
                                updatedDisplayOptions.map((o) => {
                                    if (o.id === vendorResearchId) {
                                        o.noteCount++;
                                    }
                                });

                                quote.detail.notes = newNotes;
                                this.setState({quote: updatedQuote, displayOptions: updatedDisplayOptions});
                            }}
                            reqFields={reqFields}
                            fields={fieldValues}
                            optFields={optFields}
                            lineItemId={lineItemId}
                            vendorResearchId={vendorResearchId}
                            orderId = {orderId}
                            onCancel={() => this.setState({showNotesPopup: false, isQuoteNotes: false})}
                            onSubmit={::this.getFieldValues}
                            onOrderDetailChange={::this.orderDetailChange}
                            requesterId={requesterId}
                            activeTab={isQuoteNotes ? 'QuoteNotes' : 'Notes'}
                            priority={priority}
                            isQuoteNotes={isQuoteNotes}
                            onCheckBoxUpdate={(e) => this.setState({isUrgency: e.checked})}
                        />
                        : <div />
                }
                {showSupplierEvaluationPopup ? this.renderSupplierEvaluationPopup() : null}
                {showSupplierAttachmentPopup ? this.renderSupplierAttachmentPopup() : null}
                
                {showImagePopup ? this.renderImagePopup() : null}
                {showAssetInformationAddToCartPopup ? this.renderAssetInformationPopup() : null}
                {showDeclineQuotePopup && <DeclineQuoteConfirmation 
                    detail={detail}
                    quantity={detail.quantity}
                    evalFee={options[0]?.evaluationFee}
                    show={showDeclineQuotePopup}
                    onConfirm={this.declineQuote}
                    onCancel={this.declineQuoteCancelHandler}
                    history={history} />}

            </div>);
    }

    renderFields(fields) {
        return fields.filter(x => x.value)
            .map(key => (<div key={key.prompt}>{`${key.prompt}: ${key.value}`}</div>));
    }

    renderNoQuoteAccess() {
        return <div>You do not have access to this quote.</div>
    }

    renderBusy() {
        return (<div className="product loading-prices">
            <span className="message">
      Retrieving your quote...
            </span>
            <div className="loader" />
        </div>);
    }

    renderRequestedQuote() {
        return (
            <div>
                <div className="row quoteRow">
                    <div className="col-md-12 quoteCol separator" />
                </div>
                <div style={{fontWeight: 600, marginTop: '20px', fontSize: '16px'}}>
                    We are currently working on your quote.
                </div>
            </div>);
    }

    render() {
        const {history, bloomreach: {isPreview}} = this.props;
        const {quote, loading, backToOrderHistory, fromDashboardV1, fromDashboardV2} = this.state;

        const isODS = quote && quote.detail && quote.detail.orderType === 'On Demand Service';
        const isRepair = quote && quote.detail && quote.detail.orderType === 'Repair';

        let backText = quote && quote.detail ? 'Parts Quotes' : '';

        if (backToOrderHistory) {
            backText = 'Orders';
        } 
        if (fromDashboardV1) {
            backText = 'Dashboard';
        } 
        if (fromDashboardV2) {
            backText = 'Command Center';
        } 
        if (isRepair) {
            backText = 'Depot Repairs and Quotes';
        } 

        return (
            <div className="quote-detail">
                <PageMetaData
                    title="Quote Details"
                    pageType="other"
                    trackAnalytics={true} />
                {(isODS || isPreview) && <BrComponent path="main/container" />}
                <span className="quote-label chevron">My Account</span>
                <span className="quote-sub-label">{backText}</span>
                <div className="back-link"><span onClick={() => backToOrderHistory ? history.push('/orders/history') : history.goBack()}>{`Back to ${backText}`}</span></div>
                {quote && quote.detail ? this.renderQuote() : loading ? this.renderBusy() : this.renderNoQuoteAccess()}
            </div>);
    }

    renderPricing(option) {
        const price = option.price;
        const uom = option.uom || 'Each';

        if (option.price > .01) {
            return (<div className="quote-detail_cart-panel">
                <div className="quote-detail_cart-panel_price-info">
                    <span>Your Price: </span>
                </div>
                <div className="quote-detail_cart-panel_price">
                    <span className="lbl-bold">{`${formatMoney(price)}`}</span>{` / ${uom}`}
                </div>
            </div>)
        }        
    }

    // TODO: The ImageGallery popup is now used in two places (PDP and here) so refactor
    // into its own component when time allows. This would consolidate the four methods below.

    handleImageSelected(index) {
        const innerWidth = window.innerWidth;
        if (innerWidth < 500) return;

        this.setState({
            selectedImageIndex: index,
            showImagePopup: true,
            selectedPopupImageIndex: null,
        });
    }

    previousImage() {
        const {selectedPopupImageIndex, quote: {options}, selectedOptionIndex} = this.state;
        let option = options[selectedOptionIndex];
        let images = option.partImages;
        let newSelectedPopupImageIndex = selectedPopupImageIndex - 1;

        if (newSelectedPopupImageIndex < 0) {
            this.setState({selectedPopupImageIndex: images.length - 1});
        } else {
            this.setState({selectedPopupImageIndex: newSelectedPopupImageIndex});
        }
    }

    nextImage() {
        const {selectedPopupImageIndex, quote: {options}, selectedOptionIndex} = this.state;
        let option = options[selectedOptionIndex];
        let images = option.partImages;
        let newSelectedPopupImageIndex = selectedPopupImageIndex + 1;

        if (newSelectedPopupImageIndex > images.length - 1) {
            this.setState({selectedPopupImageIndex: 0});
        } else {
            this.setState({selectedPopupImageIndex: newSelectedPopupImageIndex});
        }
    }

    renderImagePopup() {
        const {selectedImageIndex, selectedPopupImageIndex, quote: {options}, selectedOptionIndex} = this.state;
        let option = options[selectedOptionIndex];
        let popupSelectedIndex = selectedPopupImageIndex != null ? selectedPopupImageIndex : selectedImageIndex;
        let images = option.partImages;
        let image = images[popupSelectedIndex];

        return (<Popup className="quote-detail_popup modal-lg" show={true} hideButtons={true} onCancel={() => this.setState({showImagePopup: false, selectedPopupImageIndex: null})}>
            <div>
                <div className="quote-detail_popup_top-row" style={{display: 'flex', alignItems: 'center'}}>
                    {images && images.length > 1 ?
                        <i className="fa fa-chevron-left" onClick={::this.previousImage} style={{fontSize: '30px', cursor: 'pointer', color: '#cccccc'}} />
                        : null}
                    <div style={{width: '100%'}}>
                        <ProductImage url={image} altText={''} style={{width: '800px'}} />
                    </div>
                    {images && images.length > 1 ?
                        <i className="fa fa-chevron-right" onClick={::this.nextImage} style={{fontSize: '30px', cursor: 'pointer', color: '#cccccc'}} />
                        : null}
                </div>

                <div className={`quote-detail_popup_bottom-row ${options.length < 2 ? 'quote-detail_popup_bottom-row--no-options' : ''}`}>
                    <div className="quote-detail_popup_images">
                        {(images || []).map((img, index) => (
                            <div key={`pi_${index}`} onClick={() => this.setState({selectedPopupImageIndex: index})} className={`thumb${index === popupSelectedIndex ? ' thumb--selected' : ''}`}>
                                <ProductImage url={img} altText={''} style={{width: '100px'}} />
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        </Popup>)
    }

    renderSupplierEvaluationPopup() {

        const {quote: {options}, vendorResearchId} = this.state
        const optionSelected = options.find(x => x.vendorResearchId === vendorResearchId)
        const repairDiagnosisNote = optionSelected?.vendorEvaluationInfo?.customerDiagnosisNote;
        const proposedRepair = optionSelected?.vendorEvaluationInfo?.customerRepairNote;

        return (
            <Popup className="quote-detail_supplier_popup" show={true} hideButtons={true} onCancel={() => this.setState({showSupplierEvaluationPopup: false})}>
                {repairDiagnosisNote ? <SupplierNote>
                    <div className="title">{`Repair Diagnosis`}</div>
                    <p>{repairDiagnosisNote}</p>
                </SupplierNote> : null}
                {proposedRepair ? <SupplierNote>
                    <div className="title">{`Proposed Repair`}</div>
                    <p>{proposedRepair}</p>
                </SupplierNote> : null}
            </Popup>
        );
    }

    renderSupplierAttachmentPopup() {

        const {quote: {options}, vendorResearchId} = this.state;
        const optionSelected = options.find(x => x.vendorResearchId === vendorResearchId);
        const attachments = optionSelected?.vendorEvaluationInfo?.attachments;

        return (
            <Popup className="product_popup" show={true} hideButtons={true} onCancel={() => this.setState({showSupplierAttachmentPopup: false})}>
                {attachments && attachments.length > 0 ? (
                    attachments.map(item => {
                        return this.renderSupplierAttachment(item);
                    })
                ) : (
                    <p className="noFile">No Files</p>
                )}
            </Popup>
        );

    }

    renderSupplierAttachment(attachmentItem) {
        let extension = attachmentItem.name.split('.').pop();
        let blob = new Blob();

        switch (extension) {
            case 'pdf' || 'csv':
                blob = this.convertToBlob(attachmentItem.attachment, 'application/' + extension);
                break;
            case 'doc':
                blob = this.convertToBlob(attachmentItem.attachment, 'application/msword');
                break;
            case 'docx':
                blob = this.convertToBlob(attachmentItem.attachment, 'application/vnd.openxmlformats-officedocument.wordprocessingml.document');
                break;
            case 'xls':
                blob = this.convertToBlob(attachmentItem.attachment, 'application/vnd.ms-excel');
                break;
            case 'xlsx':
                blob = this.convertToBlob(attachmentItem.attachment, 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet');
                break;
            case 'txt':
                blob = this.convertToBlob(attachmentItem.attachment, 'text/plain');
                break;
            case 'html':
                blob = this.convertToBlob(attachmentItem.attachment, 'text/html');
                break;
            default:
                blob = this.convertToBlob(attachmentItem.attachment, extension);
                break;
        }

        let blobUrl = '';
        if (!navigator.userAgent.toLowerCase().includes('.net')) {
            blobUrl = URL.createObjectURL(blob);
        } 

        return (
            <div className="docDetails">
                <DocName id="downloadAttachment"
                    href={blobUrl}
                    download={attachmentItem.name}>
                    {attachmentItem.name}
                </DocName>
                <p className="docAttributes">
                    <span>Uploaded:</span>
                    <span>
                        {' '}
                        {moment(attachmentItem.dateCreated).format('dddd, MMMM D, YYYY')}
                    </span>
                </p>
                <p>
                    <span className="docAttributes">Description: </span>
                    <span>{attachmentItem.description}</span>
                </p>
            </div>
        );
    }

    convertToBlob(base64Data, contentType) {
        contentType = contentType || '';
        let sliceSize = 512;

        let byteCharacters = atob(base64Data);
        let byteArrays = [];

        for (let currentSlice = 0; currentSlice < byteCharacters.length; currentSlice += sliceSize) {
            let slice = byteCharacters.slice(currentSlice, currentSlice + sliceSize);

            let bytesContainer = new Array(slice.length);
            for (let i = 0; i < slice.length; i++) {
                bytesContainer[i] = slice.charCodeAt(i);
            }

            let byteArray = new Uint8Array(bytesContainer);

            byteArrays.push(byteArray);
        }

        let blob = new Blob(byteArrays, {type: contentType});
        return blob;
    }
}

