import PropTypes from 'prop-types';
import * as React from 'react';
import {connect} from 'react-redux';
import {withRouter} from 'react-router';
import * as ReactRouterPropTypes from 'react-router-prop-types';
import {Popup} from '@partssourceinc/react-ui-core';
import {ProductImage} from '@partssourceinc/react-ui-core';
import * as UserStore from 'stores/User';
import {formatMoney} from 'utility';

import 'less/addtocartconfirmation.less';
import 'less/carousel/carousel.less';

@withRouter
@connect(state => ({user: state.user}), UserStore.actionCreators)
export default class DeclineQuoteConfirmation extends React.Component {
    static propTypes = {
        detail: PropTypes.object.isRequired,
        quantity: PropTypes.number.isRequired,
        onCancel: PropTypes.func,
        onConfirm: PropTypes.func,
        show: PropTypes.bool,
        evalFee: PropTypes.number,
        user: UserStore.StateShape,
        ...UserStore.ActionShape,
        ...ReactRouterPropTypes,
    };
  
    static defaultProps = {
        show: false,
        quantity: 1,
        evalFee: null,
    };
  
    constructor(props) {
        super(props);
  
        this.state = {
            showPopup: props.show || false,
            saving: false,
        };
        this.handleOnCancel = this.handleOnCancel.bind(this);
        this.handleOnConfirm = this.handleOnConfirm.bind(this);
    }
  
    componentWillReceiveProps(nextProps) {
        if (nextProps.show) {
            this.setState({
                showPopup: true,
            });
        } else {
            this.setState({showPopup: false});
        }
    }
  
    closePopup() {
        const {onCancel} = this.props;
        onCancel();
    }
  
    handleOnCancel() {
        const {onCancel} = this.props;
        this.setState({showPopup: false});
  
        if (onCancel) {
            onCancel();
        }
    }
  
    handleOnConfirm = async () => {
        this.setState({saving: true});           

        const {detail: {lineItemId}, history} = this.props
        const {onConfirm} = this.props;
        if (onConfirm) {
            await onConfirm(lineItemId, history); 
            this.setState({showPopup: false, saving: false});           
        }
    }
  
    render() {
        const {
            user: {settings: {hidePricing}},
            quantity,
            detail,
            evalFee,
        } = this.props;

        const {showPopup} = this.state;

        const title = `Decline Quote`;
      
        let displayPrice = evalFee
        
        let partImage = detail.imagePath;

        const pnDisplay = detail && detail.partNumber && (<div><span><span>Item #: </span>{detail.partNumber}</span></div>);
        const priceDescription = (<div><span className="lbl-subtle">Total Cost for Evaluation Fee</span></div>);
        
        let confirmBtnTxt = `Decline Repair Quote`;
        let cancelButtonText = `Cancel`;
        return (
            <Popup
                className="cart-confirmation_popup"
                show={showPopup}
                disableClose={false}
                loading={this.state.saving ?? false}
                onCancel={this.handleOnCancel}
                onConfirm={this.handleOnConfirm}
                cancelText={cancelButtonText}
                confirmText={confirmBtnTxt}
            >
                <div className="cart-confirmation">
                    <div className="cart-confirmation_header">
                        <h2>
                            {title}
                        </h2>
                    </div>
                    
                    <div className="cart-confirmation_item">
                        <div className="cart-confirmation_item_image">
                            <ProductImage
                                url={partImage}
                                style={{width: '100px'}}
                            />
                        </div>
                            
                        <div className="cart-confirmation_item_description">
                            <a>{detail.title}</a>
                            {pnDisplay}
                            {!hidePricing && evalFee && evalFee > 0 ? <div style={{marginTop: '15px'}}>
                                {priceDescription}
                                <div className="cart-confirmation_item_price">
                                    <span className="lbl-bold">
                                        {formatMoney(displayPrice)}{' '}
                                    </span>
                                    <span className="lbl-subtle">{detail.unitOfMeasure}</span>
                                </div>
                            </div> : null}
                            
                        </div>
                            
                        <div className="cart-confirmation_item_quantity">
                            <span>Qty: </span>
                            <span className="lbl-bold">{quantity}</span>
                        </div>
                    </div>
                </div>
            </Popup>
        );
    }
}
